const easing: TimingFunction = (timeFraction: number) => 1 - Math.sin(Math.acos(timeFraction))

export default function animate(draw: DrawFunction, duration: number, onAnimationDone: any, timing: TimingFunction = easing): void {
    const start = performance.now();

    requestAnimationFrame(function animate(time) {
        let timeFraction = (time - start) / duration
        if (timeFraction > 1) timeFraction = 1;
        const progress = timing(timeFraction)

        draw(progress)

        if (timeFraction < 1) {
            requestAnimationFrame(animate);
        } else if (onAnimationDone) {
            onAnimationDone()
        }

    });
}

type TimingFunction = (timeFraction: number) => number
type DrawFunction = (progress: number) => void
