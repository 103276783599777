import './Result.sass'
import React, { Component } from 'react'
import cn from 'classnames'
import NoAuth from './NoAuth'
import Rating from './Rating'
import Winners from './Winners'
import { UserState } from '../../store/user'
import { GameState } from '../../store/game'
import { hasShared, isAuthenticated } from '../../store/user/selectors'
import GameScreen from '../../store/game/gameScreen'
import GameOverState from '../../store/game/gameOverState'
import HasAuth from './HasAuth'

enum resultScreenState {
    noAuth = 'noAuth',
    hasAuth = 'hasAuth',
    rating = 'rating',
    winners = 'winners',
}

interface ResultScreenProps {
    state: string
    game: GameState
    user: UserState
}
class ResultScreen extends Component<ResultScreenProps> {
    shouldComponentUpdate(nextProps: Readonly<ResultScreenProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        return this.props.state !== nextProps.state || this.props.game.screen === GameScreen.RESULT
    }

    render() {
        const { state, user, game } = this.props
        const resultState: resultScreenState = game.winners
            ? resultScreenState.winners
            : !isAuthenticated(user)
                ? resultScreenState.noAuth
                : !hasShared(user)
                    ? resultScreenState.hasAuth
                    : resultScreenState.rating
        const View: any = resultState === resultScreenState.noAuth
            ? NoAuth
            : resultState === resultScreenState.hasAuth
                ? game.gameOverState === GameOverState.unauthorized
                    ? HasAuth
                    : NoAuth
                : resultState === resultScreenState.rating
                    ? Rating
                    : Winners

        return (
            <div className={cn(`screen result-screen screen-${state}`, game.gameOverState)}>
                <div className='decor decor__lt' />
                <div className='decor decor__lc' />
                <div className='decor decor__lb' />
                <div className='decor decor__rt' />
                <div className='decor decor__rc' />
                <div className='decor decor__rb' />
                <View user={user} game={game} />
            </div>
        )
    }
}

export default ResultScreen
