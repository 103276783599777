import isWebview from './isWebview'
import store from '../store'
import { setShared, UserStateSharing } from '../store/user'
import { reachGoal } from './reachGoal'

const defaultSize = { width: 600, height: 450 }

const SHARING_TITLE = 'Жарь на карантине и выигрывай шашлык'

export const prepareProviderOptions = {
    vkontakte(): ProviderOptions {
        return {
            url: {
                base: 'https://vk.com/share.php',
                qs: {
                    no_vk_links: 1,
                    url: 'https://i.avs.io/mi73yh'
                }
            },
            size: defaultSize
        }
    },

    whatsapp(): ProviderOptions {
        return {
            url: {
                base: 'https://api.whatsapp.com/send',
                qs: {
                    text: encodeURIComponent(`${SHARING_TITLE} — https://i.avs.io/km1j58`),
                }
            },
            size: defaultSize
        }
    },

    telegram(): ProviderOptions {
        return {
            url: {
                base: `https://t-do.ru/share/url`,
                qs: {
                    url: 'https://i.avs.io/4df2o6',
                    text: encodeURIComponent(SHARING_TITLE),
                }
            },
            size: defaultSize
        }
    },

    facebook(): ProviderOptions {
        return {
            url: {
                base: 'https://www.facebook.com/dialog/feed',
                qs: {
                    app_id: '219887715052731',
                    display: 'popup',
                    caption: SHARING_TITLE,
                    link: 'https://i.avs.io/73blnv'
                }
            },
            size: { width: 600, height: 500 }
        }
    },
}

export function share(sharing: UserStateSharing): void {
    if (!prepareProviderOptions.hasOwnProperty(sharing.provider)) throw new Error(`There is no provider '${sharing.provider}'`)
    reachGoal('share', { shareVia: sharing.provider })
    const sharingData: ProviderOptions = prepareProviderOptions[sharing.provider]()
    const url = prepareUrl(sharingData.url)
    const closedPromise = openSharingWindow(url, sharingData.size)
    closedPromise.then(
        () => store.dispatch(setShared(sharing)),
        () => store.dispatch(setShared(sharing))
    )
}

export function openSharingWindow(url: string, options: SharingWindowSize = defaultSize): Promise<void> {
    const optionEntries = Object.keys(options).map((key) => ([key, options[key]]))
    const winOptions: string = optionEntries.reduce((acc, [key, value]) => {
        acc.push(`${key}=${value}`)
        return acc
    }, []).join('&')
    const win = window.open(url, '_blank', winOptions)
    return new Promise((resolve) => {
        if (isWebview()) return resolve();
        // eslint-disable-next-line consistent-return
        (function checkIsClosed() {
            if (win.closed) return resolve()
            setTimeout(checkIsClosed, 50)
        }())
    })
}

function prepareUrl(providerUrl: ProviderUrl): string {
    const qsEntries = Object.keys(providerUrl.qs).map((key) => ([key, providerUrl.qs[key]]))
    const qs = qsEntries.reduce((qs, [key, value]) => {
        qs.push(`${key}=${value}`)
        return qs
    }, []).join('&')
    return `${providerUrl.base}?${qs}`
}

export interface ProviderOptions {
    url: ProviderUrl
    size: SharingWindowSize
}

export interface ProviderUrl {
    base: string
    qs: { [key: string]: string | number}
}

export interface SharingWindowSize {
    width: number
    height: number
}

export interface SharingData {
    url: string
    images: {
        default: string
        [key: string]: string
    }
    title: string
    message: string
}
