import { Action } from 'redux'
import GameScreen from './gameScreen'
import GameOverReason from './gameOverReason'
import GameOverState from './gameOverState'

export const UPDATE_KEBAB = 'UPDATE_KEBAB'
export const UPDATE_GAME_STATE = 'UPDATE_GAME_STATE'
export const UPDATE_ACTIVE_KEBAB = 'UPDATE_ACTIVE_KEBAB'

export function changeGameScreen(screen: GameScreen) {
    return {
        type: UPDATE_GAME_STATE,
        payload: { screen }
    }
}

export interface GameAction extends Action {
    type: typeof UPDATE_GAME_STATE | typeof UPDATE_ACTIVE_KEBAB | typeof UPDATE_KEBAB
    payload: GameState
}

export interface GameState {
    scoreId?: string
    level?: number
    score?: number
    screen?: GameScreen
    booster?: number
    boosterEndsAt?: number
    beer?: Array<number>
    kebabs?: Array<GameKebab>
    activeKebab?: number | null
    gameOverState?: GameOverState
    gameOverReason?: GameOverReason
    top?: Array<GameWinner>
    winners?: Array<GameWinnersDay>
}

export interface GameKebab {
    img?: string
    side?: number
    score?: number
    progress?: number
    fullness?: number
    isFrying?: boolean
    fryingFactor?: number
    items?: Array<string>
}

export interface GameWinnersDay {
    date: string
    winners: Array<GameWinner>
}
export interface GameWinner {
    name: string
    score: number
    userId: string
    position: number
}
