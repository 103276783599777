import { IResourceDictionary, Texture } from 'pixi.js'

export const LIGHTNING_SHEET_KEY = '/assets/lightning.json'

export const TEXTURE_ASSETS_PRESET: Array<{ name: string, url: string }> = [
    { name: 'bat', url: '/assets/bat.png'},
    { name: 'kebabStick', url: '/assets/kebabStick.png'},
    { name: 'batCatcher', url: '/assets/batCatcher.png'},
    { name: 'rat', url: '/assets/rat.png'},
    { name: 'meat', url: '/assets/meat.png'},
    { name: 'onion', url: '/assets/onion.png'},
    { name: 'tomato', url: '/assets/tomato.png'},
    { name: 'tornado', url: '/assets/tornado.png'},
    { name: 'marshmallow', url: '/assets/marshmallow.png'},
    { name: 'displacementMap', url: '/assets/displacementMap.png'},
]

export const TEXTURE_ASSETS: { [key: string]: Texture } = {}

export function cacheTextureAssets(resources: IResourceDictionary): void {
    for (const { name } of TEXTURE_ASSETS_PRESET) {
        if (!resources[name] || !resources[name].texture) continue
        TEXTURE_ASSETS[name] = resources[name].texture
    }
}
