export default function isIntersects(ab: BodyBounds, bb: BodyBounds) {
    return ab.x + ab.width > bb.x
        && ab.x < bb.x + bb.width
        && ab.y + ab.height > bb.y
        && ab.y < bb.y + bb.height
}

export interface BodyBounds {
    x: number
    y: number
    width: number
    height: number
}
