import Axios from 'axios'
import Cookie from 'js-cookie'
import Guestia from 'guestia_client'
import store from '../store'
import { updateUser } from '../store/user'
import { isAuthenticated } from '../store/user/selectors'
import { UserType } from '../../server/modules/User/UserType'
import { UserInfo } from 'guestia_client/lib/types'
import { reachGoal } from './reachGoal'

export const getAuid = (): Promise<string> => {
    const auid = Cookie.get('auid') || Cookie.get('mamka_auid')

    if (auid) return Promise.resolve(auid.trim())

    return Axios.get('https://auid.aviasales.ru').then(({ data }) => data.trim())
}

const getUser = async (): Promise<UserType> => {
    const auid = await getAuid()
    Cookie.set('auid', auid, { domain: '.aviasales.ru', path: '/' })
    if (process.env.NODE_ENV === 'development') Cookie.set('auid', auid, { path: '/' })

    return Axios.get<UserType>('/api/v2/user').then(({ data }) => data)
}

const auid = Cookie.get('auid') || Cookie.get('mamka_auid')

const guestia = new Guestia({
    schema: {},
    host: 'https://auth.avs.io',
    authHost: 'https://auth.avs.io',
    uid: auid,
    apiVersion: 2
})

export const login = (provider): void => {
    reachGoal('auth', { authVia: provider })
    guestia.login(provider)
        .then(({ userInfo}) => createOrGetUser(userInfo))
        .then((user) => store.dispatch(updateUser(user)))
        .catch(console.error)
}

export function initGuestia(): void {
    const { user } = store.getState()
    if (isAuthenticated(user)) return

    guestia.authorize()
        .then(() => guestia.getUserInfo())
        .then(({ userInfo}) => createOrGetUser(userInfo), () => getUser())
        .then((user) => store.dispatch(updateUser(user)))
        .catch(() => {})
}

export async function createOrGetUser(userInfo: UserInfo): Promise<UserType> {
    const response = await Axios.put<UserType>('/api/v2/user', { provider: userInfo })
    let gAuid = userInfo.details.auid.find((auid) => auid !== 'undefined')
    if (!gAuid) gAuid = Cookie.get('auid') || Cookie.get('mamka_auid')
    if (!gAuid) gAuid = await getAuid()
    Cookie.set('auid', gAuid, { domain: '.aviasales.ru', path: '/', expires: 365 })
    if (process.env.NODE_ENV === 'development') Cookie.set('auid', gAuid, { path: '/', expires: 365 })

    return response.data
}

export default guestia
