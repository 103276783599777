import { UserState } from './index'
import { UserProvider } from '../../../server/modules/User/UserType'

export function isAuthenticated(userState: UserState): boolean {
    if (!userState) return false

    return Object.keys(userState.providers).some((provider) => !!userState.providers[provider])
}

export function hasShared(userState: UserState): boolean {
    if (!userState) return false

    return userState.sharings.some(({ isShared }) => !!isShared)
}

export function getUserName(userState: UserState): string {
    if (!userState.providers) return undefined
    const data: Array<UserProvider> = Object.keys(userState.providers).map((providerKey) => userState.providers[providerKey])

    for (const providerData of data) {
        if (providerData.name) return providerData.name
        else if (providerData.first_name) return `${providerData.first_name}${providerData.last_name ? ` ${providerData.last_name}` : ''}`
        else {
            for (const email of providerData.details.email) {
                if (!email.trim()) continue
                return email.split('@')[0]
            }
        }
    }
}
