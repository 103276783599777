import { Action } from 'redux'
import { UserType } from '../../../server/modules/User/UserType'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SHARING = 'UPDATE_USER_SHARING'

export const setShared = (sharing: UserStateSharing) => {
    return {
        type: UPDATE_USER_SHARING,
        payload: sharing
    }
}

export const updateUser = (userState: UserState) => {
    return {
        type: UPDATE_USER,
        payload: userState
    }
}

export interface UserAction extends Action {
    type: typeof UPDATE_USER | typeof UPDATE_USER_SHARING
    payload: UserState
}

export interface UserState extends UserType {
    sharings?: Array<UserStateSharing>
}

export interface UserStateSharing {
    provider: string
    isShared?: boolean
    isSharedDirty?: boolean
}
