const bowser: any = require('bowser');

export default function isWebview(): boolean {
    const { safari, ios, android, version } = bowser
    const { standalone, userAgent } = window.navigator as any

    if (['FBAV', 'FBBV', 'FBSN', 'FMDV', 'vkclient', 'Twitter'].some(el => (userAgent.indexOf(el) > -1))) {
        return true
    }

    if (ios) {
        if (!standalone && !safari) return true
        if (standalone && !safari) return true

        const elem = document.querySelector('#phone')
        if (elem && elem.innerHTML.indexOf('tel:') === -1) return true
    }

    if (android) {
        if (parseFloat(version) >= 42) return /; wv/.test(userAgent)
        else if (/\d{2}\.0\.0/.test(version)) return true
        return !('fullscreenEnabled' in document || 'webkitFullscreenEnabled' in document || false)
    }
    return false
};
