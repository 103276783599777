import Cookies from 'js-cookie'
import queryString from 'querystring'

export default function saveMarkerInCookies() {
    // eslint-disable-next-line no-restricted-globals
    const params = queryString.parse(location.search.slice(1))
    if (!params.marker) return
    Cookies.set('marker', params.marker, {
        expires: 30,
        domain: '.aviasales.ru'
    })
}
