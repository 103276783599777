import { UPDATE_USER, UPDATE_USER_SHARING, UserAction, UserState, UserStateSharing } from './index'
import { getJSON } from '../ls'

const initialState: UserState = {
    providers: {},
    sharings: [
        { provider: 'vkontakte' },
        { provider: 'facebook' },
        { provider: 'telegram' },
        { provider: 'whatsapp' },
    ]
}

Object.assign(initialState, getJSON('user') || {})

export default function userReducer(state: UserState = initialState, action: UserAction): UserState {
    switch (action.type) {
        case UPDATE_USER:
            return { ...state, ...action.payload }
        case UPDATE_USER_SHARING: {
            const sharing: UserStateSharing = action.payload as any
            const sharingIdx = state.sharings.indexOf(sharing)
            const sharings = [ ...state.sharings ]

            sharings[sharingIdx] = {
                ...sharings[sharingIdx],
                isShared: true,
                isSharedDirty: false,
            }

            return { ...state, sharings }
        }
    }
    return state
}
