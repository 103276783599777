import cn from 'classnames'
import React, { Component, createRef } from 'react'
import { FryingHelper, FryingTimeline } from '../FryingHelper'
import { GameKebab, GameState } from '../../../store/game'
import { endGame, finishKebab } from '../../../store/game/actions'
import { isBoosterActive } from '../../../store/game/selectors'
import GameOverReason from '../../../store/game/gameOverReason'

interface ProgressbarState {
    timeline?: FryingTimeline
    gradient?: string
    sideTimer?: number
    progress?: number
    lastTick?: number
    isBoosterActive?: boolean
}
interface ProgressbarProps {
    game: GameState
    kebab: GameKebab
    onKebabHasDone: () => void
}
class Progressbar extends Component<ProgressbarProps, ProgressbarState> {
    private barRef = createRef<HTMLDivElement>()
    private animationFrameId: number

    constructor(props: ProgressbarProps) {
        super(props)
        this.state = {}
    }

    componentWillUnmount(): void {
        if (this.animationFrameId) cancelAnimationFrame(this.animationFrameId)
    }

    componentDidUpdate(prevProps: Readonly<ProgressbarProps>, prevState: Readonly<{}>): void {
        const { kebab, game, onKebabHasDone } = this.props
        const { gradient } = this.state
        const currentKebabIdx = game.kebabs.indexOf(kebab)

        if (!!game.gameOverReason) return

        if (!gradient && kebab.isFrying) {
            // Start frying
            const timeline = FryingHelper.getFryingTimeline(game.level)
            const gradient = FryingHelper.getFryingGradient(game.level, kebab.side)
            const lastTick = performance.now()

            this.setState({ timeline, gradient, lastTick, sideTimer: 0 }, () => {
                const self = this

                this.animationFrameId = requestAnimationFrame(function updateProgress() {
                    const now = performance.now()
                    const { lastTick, timeline, sideTimer } = self.state
                    const newSideTimer = sideTimer + now - lastTick
                    const progress = Math.min(newSideTimer / (timeline.duration * 1000), 1)

                    if (progress >= 1) {
                        endGame(game, GameOverReason.LATE)
                        if (self.animationFrameId) cancelAnimationFrame(self.animationFrameId)
                        return
                    }

                    self.setState({
                        lastTick: now,
                        sideTimer: newSideTimer,
                        progress,
                        isBoosterActive: isBoosterActive(self.props.game)
                    })
                    self.animationFrameId = requestAnimationFrame(updateProgress)
                })
            })
        } else if (kebab.isFrying && prevProps.kebab.side !== kebab.side) {
            if (kebab.side > 3) {
                if (this.animationFrameId) cancelAnimationFrame(this.animationFrameId)
                // Kebab is done
                finishKebab(currentKebabIdx, game)
                onKebabHasDone()
            } else {
                const { sideTimer } = this.state
                const fryingFactor = FryingHelper.getFryingFactor(game, sideTimer, kebab.side)
                if (fryingFactor !== 0) {
                    if (this.animationFrameId) cancelAnimationFrame(this.animationFrameId)
                    endGame(game, fryingFactor === -1 ? GameOverReason.EARLY : GameOverReason.LATE)
                    return
                }
                this.setState({ sideTimer: 0, gradient: FryingHelper.getFryingGradient(game.level, kebab.side) })
            }
        } else if (prevProps.kebab.isFrying && !kebab.isFrying) {
            // Stop frying
            this.setState({ gradient: null, timeline: null })
            if (this.animationFrameId) cancelAnimationFrame(this.animationFrameId)
        }
    }

    render() {
        const { gradient, progress, isBoosterActive } = this.state

        if (!gradient) return null
        const transform = `translateY(${this.barRef.current ? (this.barRef.current.offsetHeight - progress * this.barRef.current.offsetHeight) : 0}px)`

        return (
            <div className={cn('progress', isBoosterActive && 'progress_boosted')}>
                <div className='progress__bar' ref={this.barRef} style={{ backgroundImage: gradient }} />
                <div className='progress__icon' />
                <div className='progress__arrow' style={{ transform }} />
            </div>
        )
    }
}

export default Progressbar
