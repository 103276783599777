import React, { FC, useRef } from 'react'
import Progressbar from './Progressbar'
import { GameKebab, GameState } from '../../../store/game'
import cn from 'classnames'
import CoinsAnimation from '../../../CatcherGame/misc/coinsAnimation'
import soundManager, { Sounds } from '../../../misc/soundManager'

interface KebabProps {
    kebab: GameKebab
    game: GameState
}
const Kebab: FC<KebabProps> = ({ kebab, game }) => {
    const kebabImgRef = useRef<HTMLDivElement>()

    const onKebabHasDone = () => {
        if (!kebabImgRef.current) return
        const fromBounds = kebabImgRef.current.getBoundingClientRect()
        const toBounds = document.querySelector('.booster_beer').getBoundingClientRect()
        const from = { x: fromBounds.x + fromBounds.width / 2, y: fromBounds.y + fromBounds.height / 2 }
        const to = { x: toBounds.x + toBounds.width / 2, y: toBounds.y + toBounds.height / 2 }
        CoinsAnimation.start(from, to)
        soundManager.play(Sounds.coins)
    }

    return (
        <div className={cn('kebab', `kebab_${kebab.side}`)}>
            <Progressbar
                game={game}
                kebab={kebab}
                onKebabHasDone={onKebabHasDone}
            />
            <div className='kebab__img' ref={kebabImgRef} style={{ backgroundImage: `url(${kebab.img})` }} />
        </div>
    )
}

export default Kebab
