import React, { FC } from 'react'
import { UserState } from '../../store/user'
import { share } from '../../misc/sharingHelper'
import { startGame } from '../../store/game/actions'
import RulesLink from '../Rules'
import { reachGoal } from '../../misc/reachGoal'

interface HasAuthProps {
    user: UserState
}
const HasAuth: FC<HasAuthProps> = ({ user }) => {
    return (
        <div className='result result_has-auth'>
            <div className='result__content'>
                <div className='result__title'>Молодец</div>
                <br/><br/>
                <div className='result__text'>
                    Расскажи друзьям, чтобы увидеть свой&nbsp;рейтинг
                </div>
                <div className='result__header'>Расскажи друзьям</div>
                <div className='result__social result__social_share'>
                    {user.sharings.map((sharing) => (
                        <button
                            key={sharing.provider}
                            onClick={() => share(sharing)}
                            className={`result__social-btn result__social-btn_${sharing.provider}`}
                        />
                    ))}
                </div>
            </div>

            <button className='result__play-again thin' onClick={() => { startGame(); reachGoal('restartgame')}}>Играть ещё</button>
            <RulesLink />
        </div>
    )
}

export default HasAuth
