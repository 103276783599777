import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import ReduxThunk from 'redux-thunk'
import userReducer from './user/reducer'
import { UserState } from './user'
import gameReducer from './game/reducer'
import { GameState } from './game'
import { localStorageMiddleware } from './ls'

const rootReducer = combineReducers({
    user: userReducer,
    game: gameReducer,
})

const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose

const lsMiddleware = localStorageMiddleware([
    {
        key: 'user',
        serializer: (state) => state.user
    }
])

const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(ReduxThunk, lsMiddleware)
    )
)

export default store

export interface StoreState {
    user: UserState
    game: GameState
}
