declare const ym: any
declare const ga: any

export function reachGoal(goal: string | Array<string>, params?: any): void {
    console.log('goal reached', goal)
    const goalList: Array<string> = Object.prototype.toString.call(goal) === '[object Array]'
        ? goal as any
        : [ goal ]
    for (const goalItem of goalList) {
        try {
            ym(61724017, 'reachGoal', goalItem, params)
        } catch (e) {
            // :(
        }
        try {
            ga.getAll().forEach((tracker) => tracker.send('event', goalItem, 'click'))
        } catch (e) {
            // :(
        }
    }
}
