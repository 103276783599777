import { StoreState } from './index'

const LS_KEY = 'as_zharim_1.0_'

export const storeJSON = (key: string, data: any) => localStorage.setItem(`${LS_KEY}${key}`, JSON.stringify(data))

export const getJSON = (key: string) => {
    const data = localStorage.getItem(`${LS_KEY}${key}`)
    if (!data) return undefined
    return JSON.parse(data)
}

export const localStorageMiddleware = (serializers: Array<LocalStorageSerializer>) => (store) => (next) => (action) => {
    const result = next(action)
    const state = store.getState()
    serializers.forEach(({ key, serializer }) => storeJSON(key, serializer(state)))
    return result
}

export interface LocalStorageSerializer {
    key: string
    serializer: (store: StoreState) => object
}
