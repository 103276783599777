import { Point, Sprite, Texture } from 'pixi.js'

export default class KebabComponent extends Sprite {
    public readonly name: string

    static getRandomPoint(minX: number, maxX: number, minY = minX, maxY = maxX, factor = 1): Point {
        const x = (minX + Math.floor(Math.random() * (maxX - minX))) * factor
        const y = (minY + Math.floor(Math.random() * (maxY - minY))) * factor
        return new Point(x, y)
    }

    constructor(texture: Texture, public readonly velocity: Point, public angularVelocity: number) {
        super(texture);
        this.name = texture.textureCacheIds[0]
        this.anchor.set(0.5)
    }

    updateVelocity(velocity: Point): void {
        this.velocity.set(velocity.x, velocity.y)
    }

    update(deltaTime: number): void {
        this.x += this.velocity.x * deltaTime
        this.y += this.velocity.y * deltaTime
        this.angle += this.angularVelocity * deltaTime
    }
}
