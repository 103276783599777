import React, { FC } from 'react'
import prettyNumber from '../../misc/prettyNumber'
import { login } from '../../misc/guestia'
import { GameState } from '../../store/game'
import GameOverReason from '../../store/game/gameOverReason'
import { startGame } from '../../store/game/actions'
import RulesLink from '../Rules'
import { UserState } from '../../store/user'
import { isAuthenticated } from '../../store/user/selectors'
import { share } from '../../misc/sharingHelper'
import getGameOverReasonText from './getGameOverReasonText'
import { reachGoal } from '../../misc/reachGoal'

const providers = [
    'vk',
    'facebook',
    'twitter',
    'google',
]

interface NoAuthProps {
    game: GameState
    user: UserState
}
const NoAuth: FC<NoAuthProps> = ({ user, game }) => {
    const performLogin = (provider: string) => login(provider)

    return (
        <div className='result result_no-auth'>
            <div className={`result__content ${game.gameOverReason?.toLowerCase()}`}>
                <div className='result__title'>{getGameOverReasonText(game.gameOverReason)}</div>
                <div className='result__row'>
                    <div className='result__score'>
                        <div className='result__score-icon' />
                        <div className='result__score-content'>
                            <div className='result__score-title'>Твой счет</div>
                            <div className='result__score-value'>{prettyNumber(game.score)}</div>
                        </div>
                    </div>
                </div>
                {isAuthenticated(user)
                    ? (
                        <>
                            <div className='result__header'>Расскажи друзьям и смотри лидерборд</div>
                            <div className='result__social result__social_share'>
                                {user.sharings.map((sharing) => (
                                    <button
                                        key={sharing.provider}
                                        onClick={() => share(sharing)}
                                        className={`result__social-btn result__social-btn_${sharing.provider}`}
                                    />
                                ))}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='result__header'>Авторизуйся и смотри лидерборд</div>
                            <div className='result__social result__social_auth'>
                                {providers.map((provider) => (
                                    <button
                                        key={provider}
                                        onClick={() => performLogin(provider)}
                                        className={`result__social-btn result__social-btn_${provider}`}
                                    />
                                ))}
                            </div>
                        </>
                    )
                }
            </div>
            <button className='result__play-again thin' onClick={() => { startGame(); reachGoal('restartgame')}}>Играть ещё</button>
            <RulesLink />
        </div>
    )
}

export default NoAuth
