import React, { Component, createRef } from 'react'
import CatcherGameScene from './Game'

export default class CatcherGame extends Component {
    private canvasRef = createRef<HTMLCanvasElement>()
    private gameScene: CatcherGameScene

    componentDidMount(): void {
        if (!this.canvasRef.current) return
        this.gameScene = new CatcherGameScene(this.canvasRef.current)
    }

    render() {
        return (
            <canvas
                ref={this.canvasRef}
                className='render-canvas'
            />
        )
    }
}
