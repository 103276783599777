import { Howl } from 'howler'

export enum Sounds {
    bg = 'bg',
    btnHover = 'btnHover',
    btnPress = 'btnPress',
    coins = 'coins',
    energySelector = 'energySelector',
    energyPicker = 'energyPicker',
    swoosh = 'swoosh',
    thingPicker = 'thingPicker',
    drinkBeer = 'drinkBeer'
}

const PRESETS: Array<Preset> = [
    { name: Sounds.bg, src: [ '/sound/hardbass_track.mp3' ], loop: true, volume: 0.5 },
    { name: Sounds.btnHover, src: [ '/sound/button_hover.mp3' ], volume: 0.5 },
    { name: Sounds.btnPress, src: [ '/sound/button_press.mp3' ], volume: 0.5 },
    { name: Sounds.coins, src: [ '/sound/coins.mp3' ], volume: 0.5 },
    { name: Sounds.thingPicker, src: [ '/sound/thing_picker.mp3' ], volume: 0.5 },
    { name: Sounds.energyPicker, src: [ '/sound/energy_picker.mp3' ], volume: 0.9 },
    { name: Sounds.drinkBeer, src: [ '/sound/time_to_drink_a_beer.mp3' ], volume: 0.6 },
    { name: Sounds.swoosh, src: [ '/sound/swoosh.mp3' ], volume: 0.6 },
    { name: Sounds.energySelector, src: [ '/sound/enegry_selector.mp3' ], volume: 0.6 },
]

const soundManager = new class {
    private isEnabled = false
    private isPaused = false
    private sounds: { [key: string]: Howl } = {}
    private handlers: Array<() => void> = []
    private bgSoundId: number

    constructor() {
        for (const preset of PRESETS) {
            this.sounds[preset.name] = new Howl(preset)
        }
    }

    pauseAll(): void {
        this.isPaused = true
        this.setEnabled(false)
    }

    resumeAll(): void {
        if (!this.isPaused) return
        this.isPaused = false
        this.setEnabled(true)
    }

    addEnabledChangeListener(handler: () => void): void {
        this.handlers.push(handler)
    }

    getEnabled(): boolean {
        return this.isEnabled
    }

    setEnabled(isEnabled: boolean): void {
        this.isEnabled = isEnabled
        this.handlers.forEach((handler) => handler())
        if (this.isEnabled) {
            this.playBackground()
        } else {
            Object.keys(this.sounds).forEach((key) => this.sounds[key].stop())
        }
    }

    playBackground(): void {
        if (this.sounds[Sounds.bg].playing(this.bgSoundId)) return
        this.bgSoundId = this.sounds[Sounds.bg].play(this.bgSoundId)
        this.sounds[Sounds.bg].fade(0, 1, 10000, this.bgSoundId)
    }

    play(sound: Sounds): void {
        if (!this.isEnabled) return
        this.sounds[sound].play()
    }
}()

interface Preset extends IHowlProperties {
    name: string
}

export default soundManager
