import './Grill.sass'
import React, { FC } from 'react'
import cn from 'classnames'
import { GameKebab, GameState } from '../../../store/game'
import Kebab from './Kebab'
import { updateKebab } from '../../../store/game/actions'
import soundManager, { Sounds } from '../../../misc/soundManager'

interface GrillProps {
    game: GameState
}
const Grill: FC<GrillProps> = ({ game }) => {
    const kebabItems = game.kebabs.slice()
    if (kebabItems.length < 2) kebabItems.push(null)

    const flipKebab = (kebabIdx: number, kebab: GameKebab) => {
        updateKebab(kebabIdx, { side: kebab.side + 1 })
        soundManager.play(Sounds.swoosh)
    }

    return (
        <div className='grill'>
            {kebabItems.map((kebab, idx) => (
                <div key={idx} className={cn('grill__zone', `grill__zone_${idx + 1}`)}>
                    {!kebab.isFrying ? null : (
                        <>
                            <Kebab game={game} kebab={kebab} />
                            <button
                                onClick={() => flipKebab(idx, kebab)}
                                className='grill__switch'
                            >
                                Перевернуть
                            </button>
                        </>
                    )}
                </div>
            ))}
        </div>
    )
}

export default Grill
