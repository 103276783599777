import './Catcher.sass'
import React, { FC } from 'react'
import AnimatedNumber from 'react-animated-number'
import { GameState } from '../../store/game'
import prettyNumber from '../../misc/prettyNumber'

interface CatcherScreenProps {
    game: GameState
    state: string
}
const CatcherScreen: FC<CatcherScreenProps> = ({ game, state }) => {
    const activeKebab = game.kebabs[game.activeKebab]
    const kebabScore = activeKebab ? activeKebab.score : 0

    return (
        <div className={`screen catcher-screen screen-${state}`}>
            <div className='catcher-screen__hud'>
                <div className='catcher-screen__progress'>
                    <div className='catcher-screen__progress-bar' style={{ transform: `scaleX(${activeKebab?.fullness || 0})` }} />
                </div>
                <div className='score'>
                    <div className='score__bg' />
                    <div className='score__icon' />
                    <div className='score__value'>
                        <AnimatedNumber
                            duration={400}
                            stepPrecision={0}
                            value={game.score + kebabScore}
                            formatValue={(score) => prettyNumber(score)}
                        />
                    </div>
                </div>
            </div>
            <div className='catcher-screen__round round'>Раунд {game.level}</div>
        </div>
    )
}

export default CatcherScreen
