import { Application, Container, SCALE_MODES, Sprite, Texture } from 'pixi.js'
import { TEXTURE_ASSETS } from '../assets'
import KebabComponent from './KebabComponent'
import { endGame, updateActiveKebab } from '../../store/game/actions'
import { calculateScore, isValidComponents } from '../misc/componentWeights'
import GameOverReason from '../../store/game/gameOverReason'
import { GameState } from '../../store/game'

const OVERRIDES = {
    batCatcher: 'bat'
}

const MAX_COMPONENTS = 7
const COMPONENT_OFFSET = 178
const COMPONENT_MARGIN = 50

export default class Kebab {
    public texture: Texture
    private container: Container
    private components: Array<string> = []

    get isFull(): boolean {
        return this.components.length >= MAX_COMPONENTS
    }

    get componentList(): Array<string> {
        return this.components
    }

    constructor(private readonly app: Application, public gameState: GameState) {
        const baseTexture = TEXTURE_ASSETS['kebabStick']
        const stickSprite = new Sprite(baseTexture)
        stickSprite.x = 0
        stickSprite.y = 0
        stickSprite.width = baseTexture.width
        stickSprite.height = baseTexture.height
        this.container = new Container()
        this.container.width = baseTexture.width
        this.container.height = baseTexture.height
        this.container.addChild(stickSprite)
        this.texture = this.app.renderer.generateTexture(this.container, SCALE_MODES.LINEAR, devicePixelRatio || 1)
    }

    addComponent(component: KebabComponent): void {
        const texture = OVERRIDES[component.name] ? TEXTURE_ASSETS[OVERRIDES[component.name]] : TEXTURE_ASSETS[component.name]
        const sprite = new Sprite(texture)
        sprite.anchor.set(1, 0.5)
        sprite.x = COMPONENT_OFFSET + this.components.length * COMPONENT_MARGIN
        sprite.y = this.texture.height / 2
        this.container.addChild(sprite)

        this.texture = this.app.renderer.generateTexture(this.container, SCALE_MODES.LINEAR, devicePixelRatio || 1)
        this.components.push(component.name)
        if (isValidComponents(this.componentList)) {
            updateActiveKebab({
                fullness: this.components.length / MAX_COMPONENTS,
                score: calculateScore(this.components),
                items: this.components
            })
        } else {
            endGame(this.gameState, GameOverReason.INVALID)
        }
    }

    public destroy(): void {
        this.container.destroy({ children: true })
        this.texture.destroy(true)
    }

    public getTextureImg(): string {
        const sprite = new Sprite(this.texture)
        sprite.angle = -90
        sprite.anchor.set(0.5)
        sprite.width = this.texture.width
        sprite.height = this.texture.height
        sprite.x = this.texture.width / 2
        sprite.y = this.texture.height / 2
        const container = new Container()
        container.width = this.texture.height
        container.height = this.texture.width
        container.addChild(sprite)
        const exportImg = this.app.renderer.extract.base64(container)
        container.destroy({ children: true, texture: true })
        return exportImg
    }
}
