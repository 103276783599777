import { GameAction, GameState, UPDATE_ACTIVE_KEBAB, UPDATE_GAME_STATE, UPDATE_KEBAB } from './index'
import GameScreen from './gameScreen'
import kebabFactory from './kebabFactory'
import { isBoosterActive } from './selectors'

const initialState: GameState = {
    level: 1,
    score: 0,
    screen: GameScreen.LOADING,
    booster: 0,
    beer: [],
    activeKebab: null,
    kebabs: [
        kebabFactory(),
        kebabFactory(),
    ],
    top: []
}

export default function gameReducer(state: GameState = initialState, action: GameAction): GameState {
    switch (action.type) {
        case UPDATE_GAME_STATE:
            return { ...state, ...action.payload }
        case UPDATE_ACTIVE_KEBAB: {
            const newState = { ...state }
            const payload: any = action.payload
            newState.kebabs[state.activeKebab] = {
                ...newState.kebabs[newState.activeKebab],
                ...payload.kebab
            }
            if (isBoosterActive(state)) {
                // If booster activated, and only 1 kebab is Frying then add 2d kebabs
                const otherKebabIdx = state.activeKebab === 0 ? 1 : 0
                if (!state.kebabs[otherKebabIdx].isFrying) {
                    newState.kebabs[otherKebabIdx] = { ...newState.kebabs[state.activeKebab] }
                }
            }
            newState.kebabs = [ ...newState.kebabs ]
            if (payload.changeScreen) {
                newState.activeKebab = null
                newState.screen = GameScreen.FRYING
            }

            return newState
        }
        case UPDATE_KEBAB: {
            const newState = { ...state }
            const payload: any = action.payload
            newState.kebabs[payload.kebabIdx] = {
                ...newState.kebabs[payload.kebabIdx],
                ...payload.kebab
            }

            return newState
        }
    }

    return state
}
