import { Application, AnimatedSprite, SimpleRope, Point } from 'pixi.js'
import KebabComponentsEmitter from './KebabComponentsEmitter'
import Kebab from './Kebab'
import Viewport from '../misc/Viewport'
import { Spring, SpringPoint } from '../misc/Spring'
import isIntersects, { BodyBounds } from '../misc/isIntersects'
import { endGame, kebabReadyForFrying, updateActiveKebab, updateGameState } from '../../store/game/actions'
import { calculateScore, isValidComponents } from '../misc/componentWeights'
import { GameState } from '../../store/game'
import GameOverReason from '../../store/game/gameOverReason'
import KebabComponent from './KebabComponent'
import soundManager, { Sounds } from '../../misc/soundManager'

const ROPE_SEGMENTS = 2
const DESKTOP_CONSTRAINT = 1000
const TRAP_SIZE = 4

export default class Trap {
    private kebab: Kebab
    private strip: SimpleRope
    private spring: Spring
    private points: Array<Point> = []
    private stickPosition = 0
    private lightning: AnimatedSprite
    private stickTargetPosition = Viewport.width / 2

    constructor(
        private gameState: GameState,
        private readonly app: Application,
        private readonly emitter: KebabComponentsEmitter) {
        this.initListeners()
    }

    public updateGameState(newGameState: GameState): void {
        this.gameState = newGameState
        if (this.kebab) this.kebab.gameState = newGameState
        if (!this.spring) return
        this.spring.updateDamping(Math.max(1 - this.gameState.level * 0.2, 0.1))
        this.spring.updateTension(Math.max(0.5 - this.gameState.level * 0.1, 0.025))
    }

    public setLightning(lightning: AnimatedSprite): void {
        this.lightning = lightning
    }

    public start(): void {
        this.kebab = new Kebab(this.app, this.gameState)

        if (!this.strip) this.initStrip()
    }

    public stop(): void {
        this.app.stage.removeChild(this.strip)
        this.kebab.destroy()
        this.kebab = null
        this.strip.destroy()
        this.strip = null
        this.points = []
        this.spring = null
    }

    public update(deltaTime: number): void {
        if (!this.strip) return
        this.stickPosition = this.stickTargetPosition

        this.strip.x = this.stickPosition
        this.spring.update(this.strip.x, deltaTime)
        this.spring.points.forEach(({ position }, idx) => {
            this.points[idx].y = position - this.stickPosition
        })

        this.strip.texture = this.kebab.texture

        this.checkComponentsCatch()
    }

    private checkComponentsCatch(): void {
        const trapSize = TRAP_SIZE * Viewport.getScale()
        const topOfStick = this.points[this.points.length - 1]
        const x = this.strip.position.x + topOfStick.y * Viewport.getScale(true) - trapSize * 2
        const y = Viewport.height - topOfStick.x * Viewport.getScale(true) + trapSize * 6
        const bounds: BodyBounds = { x, y, width: trapSize * 4, height: trapSize }

        for (const component of this.emitter.activeComponents) {
            if (this.kebab.isFull) return this.onKebabReady()
            if (!isIntersects(bounds, component)) continue
            if (component.name !== 'tornado') {
                this.kebab.addComponent(component)
                soundManager.play(Sounds.thingPicker)
            } else {
                this.onCatchTornado()
                soundManager.play(Sounds.energyPicker)
            }
            this.emitter.deactivate(component)
        }
    }

    public resize(): void {
        if (!this.strip) return
        const scale = Viewport.getScale(true)
        const kebabTexture = this.kebab.texture
        this.strip.y = Viewport.height
        this.strip.width = kebabTexture.width * scale
        this.strip.height = kebabTexture.height * scale
    }

    private initStrip(): void {
        const kebabTexture = this.kebab.texture
        const scale = Viewport.getScale(true)

        const springPoints: Array<SpringPoint> = []
        const ropeSegmentLength = kebabTexture.width / ROPE_SEGMENTS
        for (let i = 0; i < ROPE_SEGMENTS; i++) {
            this.points.push(new Point(i * ropeSegmentLength, 0))
            springPoints.push(new SpringPoint(this.stickPosition))
        }
        this.points.push(new Point(kebabTexture.width, 0))
        springPoints.push(new SpringPoint(this.stickPosition))
        this.spring = new Spring(springPoints, 0.95, 0.5)

        this.strip = new SimpleRope(kebabTexture, this.points)
        this.strip.x = this.stickPosition
        this.strip.y = Viewport.height
        this.strip.angle = -90
        this.strip.width = kebabTexture.width * scale
        this.strip.height = kebabTexture.height * scale

        this.app.stage.addChild(this.strip)
    }

    private initListeners(): void {
        window.addEventListener('mousemove', ({ clientX }) => {
            const vw = Viewport.width
            if (vw < DESKTOP_CONSTRAINT) return clientX
            const min = vw / 2 - DESKTOP_CONSTRAINT / 2
            const max = vw / 2 + DESKTOP_CONSTRAINT / 2
            if (clientX < min || clientX > max) return
            this.stickTargetPosition = clientX
        })
        window.addEventListener('touchstart', (e) => {
            this.stickTargetPosition = e.touches[0].clientX
        })
        window.addEventListener('touchmove', (e) => {
            this.stickTargetPosition = e.touches[0].clientX
        })
    }

    private onKebabReady(): void {
        const components = this.kebab.componentList
        if (!isValidComponents(components)) {
            endGame(this.gameState, GameOverReason.INVALID)
            return
        }
        updateActiveKebab({
            side: 0,
            progress: 0,
            isFrying: true,
            fryingFactor: 0,
            items: components,
            img: this.kebab.getTextureImg(),
            score: calculateScore(components)
        }, true)
        kebabReadyForFrying(this.gameState)
    }

    private onCatchTornado(): void {
        updateGameState({ booster: Math.min(this.gameState.booster + 0.125, 1) })
        this.lightning.alpha = 1
        this.lightning.width = Viewport.height
        this.lightning.height = Viewport.height
        this.lightning.x = Viewport.width / 2
        this.lightning.y = Viewport.height / 2
        this.lightning.gotoAndPlay(0)
        // Remove bad items
        const badItems = this.emitter.activeComponents.filter(({ name }) => name === 'bat' || name === 'rat' || name === 'batCatcher')
        let item: KebabComponent
        while (item = badItems.pop()) this.emitter.deactivate(item)
    }
}
