import { Application, Container } from 'pixi.js'
import KebabComponent from './KebabComponent'
import { TEXTURE_ASSETS } from '../assets'
import isIntersects from '../misc/isIntersects'
import Viewport from '../misc/Viewport'
import { GameState } from '../../store/game'

const SPAWN_CONSTRAINT = 20

export default class KebabComponentsEmitter {
    private readonly activated: Pool = []
    private readonly deactivated = new Map<string, Pool>()
    private timeoutId: number
    private readonly componentNames: Array<string> = []

    private getSpawnInterval(): number {
        const fac = this.gameState.level * 25
        return 500 - fac + Math.floor(Math.random() * (1000 - fac))
    }

    static isComponentInViewport(component: KebabComponent): boolean {
        return isIntersects(Viewport.bounds, component)
    }

    get activeComponents(): Pool {
        return this.activated
    }

    constructor(
        private readonly app: Application,
        private readonly stage: Container,
        private gameState: GameState) {}

    public updateGameState(newGameState: GameState): void {
        this.gameState = newGameState
    }

    public init(): void {
        this.componentNames.push(
            'tornado',
            'rat',
            'meat',
            'onion',
            'tomato',
            'batCatcher',
            'marshmallow',
        )
    }

    public start(): void {
        const self = this;
        (function spawnLoop(): void {
            self.spawn(self.getRandomElementToSpawn())
            self.timeoutId = window.setTimeout(spawnLoop, self.getSpawnInterval())
        })()
    }

    public stop(): void {
        if (this.timeoutId) clearTimeout(this.timeoutId)

        let component: KebabComponent
        while (component = this.activated.pop()) {
            this.deactivate(component)
        }
    }

    public update(deltaTime: number): void {
        for (const component of this.activated) {
            if (!KebabComponentsEmitter.isComponentInViewport(component)) {
                this.deactivate(component)
                continue
            }
            component.update(deltaTime)
        }
    }

    private getRandomElementToSpawn(): string {
        const random = Math.random()
        if (random < 0.1) return this.componentNames[0]
        return this.componentNames[1 + Math.floor(random * (this.componentNames.length - 1))]
    }

    private spawn(componentName: string, level: number = this.gameState.level): void {
        if (!this.deactivated.get(componentName)) this.deactivated.set(componentName, [])
        let component = this.deactivated.get(componentName).pop()

        const velocity = KebabComponent.getRandomPoint(0, 0, 1.5 + level, 5 + level, 0.03)
        const angularVelocity = Math.random() * (Math.random() < 0.5 ? -0.1 : 0.1)

        if (component) {
            component.visible = true
            component.updateVelocity(velocity)
            component.angularVelocity = angularVelocity
        } else {
            if (!TEXTURE_ASSETS[componentName]) throw new Error(`Texture ${componentName} doesn't exist`)
            component = new KebabComponent(TEXTURE_ASSETS[componentName], velocity, angularVelocity)
            this.stage.addChild(component)
        }

        const boundsConstraint = Viewport.width >= 1000 ? Viewport.width - 1000 / 2 + SPAWN_CONSTRAINT: SPAWN_CONSTRAINT
        component.x = boundsConstraint + Math.floor(Math.random() * (Viewport.width - boundsConstraint * 2))
        component.y = -SPAWN_CONSTRAINT
        component.scale.set(Viewport.getScale(true) * 0.75)

        this.activated.push(component)
    }

    public deactivate(component: KebabComponent): void {
        if (!this.deactivated.get(component.name)) this.deactivated.set(component.name, [])
        component.visible = false
        const componentIdx = this.activated.indexOf(component)
        if (componentIdx !== -1) this.activated.splice(componentIdx, 1)
        const pool = this.deactivated.get(component.name)
        pool.push(component)
    }

    public tornadoSpawn(): void {
        const count = Viewport.width > 743 ? 10 : 5
        const spawnCount = count + Math.floor(Math.random() * count)
        for (let i = 0; i < spawnCount; i++) this.spawn(this.getRandomElementToSpawn(), this.gameState.level + 2)
    }
}

type Pool = Array<KebabComponent>
