import './App.sass'
import React, { Component } from 'react'
import cn from 'classnames'
import UI from './UI'
import { StoreState } from './store'
import { connect } from 'react-redux'
import { changeGameScreen } from './store/game'
import { preload } from './loader'
import CatcherGame from './CatcherGame'
import GameScreen from './store/game/gameScreen'
import soundManager from './misc/soundManager'
import { initWinners } from './store/game/actions'

interface AppState {
    soundEnabled: boolean
}
interface AppProps extends StoreState {
    changeGameScreen: typeof changeGameScreen
}
class App extends Component<AppProps, AppState> {

    get viewClass(): string {
        const { game } = this.props
        return game.screen === GameScreen.LOADING
            ? 'loading-view'
            : game.screen === GameScreen.CATCHER
                ? 'game-view'
                : 'ui-view'
    }

    constructor(props: AppProps) {
        super(props)
        this.state = {
            soundEnabled: false
        }
    }

    componentDidMount(): void {
        preload().then(() => {
            const { changeGameScreen } = this.props
            if (window.location.pathname === '/result') {
                changeGameScreen(GameScreen.RESULT)
            } else if (window.location.pathname === '/winners') {
                changeGameScreen(GameScreen.RESULT)
                initWinners()
            } else {
                changeGameScreen(GameScreen.TITLE)
            }
        })
        soundManager.addEnabledChangeListener(() => {
            this.setState({ soundEnabled: soundManager.getEnabled() })
        })
    }

    render() {
        const { user, game } = this.props
        const { soundEnabled } = this.state

        return (
            <div className={cn('app', this.viewClass)}>
                <CatcherGame />
                <UI user={user} game={game} />
                <button
                    onClick={this.toggleSoundEnabled}
                    className={cn('sound-switch', `sound-switch_${soundEnabled ? 'enabled' : 'disabled'}`)} />
            </div>
        )
    }

    private toggleSoundEnabled = () => {
        soundManager.setEnabled(!this.state.soundEnabled)
    }
}

const mapStateToProps = (state: StoreState) => state

const mapDispatchToProps = (dispatch) => ({
    changeGameScreen: (screen: GameScreen) => dispatch(changeGameScreen(screen))
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
