import './Frying.sass'
import React, { Component, createRef } from 'react'
import AnimatedNumber from 'react-animated-number'
import cn from 'classnames'
import Grill from './Grill'
import prettyNumber from '../../misc/prettyNumber'
import { GameState } from '../../store/game'
import { activateBooster, lvlUp, setActiveKebab } from '../../store/game/actions'
import CoinsAnimation from '../../CatcherGame/misc/coinsAnimation'
import GameScreen from '../../store/game/gameScreen'
import RulesLink from '../Rules'
import { reachGoal } from '../../misc/reachGoal'
import soundManager, { Sounds } from '../../misc/soundManager'

interface FryingScreenState {
    tornadoProgress: number
}
interface FryingScreenProps {
    game: GameState
    state: string
}
class FryingScreen extends Component<FryingScreenProps, FryingScreenState> {
    private scoreRef = createRef<HTMLDivElement>()
    private beerRef = createRef<HTMLButtonElement>()
    private tornadoRef = createRef<HTMLButtonElement>()

    constructor(props: FryingScreenProps) {
        super(props)
        this.state = {
            tornadoProgress: 0
        }
    }

    shouldComponentUpdate(nextProps: Readonly<FryingScreenProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        return this.props.state !== nextProps.state || this.props.game.screen !== GameScreen.CATCHER
    }

    render() {
        const { game, state } = this.props
        const userAction = this.getUserAction()
        const beerTransform = `scaleY(${ game.beer.length ? 1 : 0 })`
        const tornadoTransform = `scaleY(${ Math.min(game.booster, 1) })`

        return (
            <div className={`screen frying-screen screen-${state} ${userAction}`}>
                <div className='decor decor__lt' />
                <div className='decor decor__lc' />
                <div className='decor decor__lb' />
                <div className='decor decor__b' />
                <div className='decor decor__rt' />
                <div className='decor decor__rc' />
                <div className='decor decor__rb' />
                <header className='header'>
                    <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href='https://aviasales.ru'
                        className='header__left_logo'
                    />
                    <a
                        target='_blank'
                        rel='noopener noreferrer'
                        onClick={() => reachGoal('tornado')}
                        href='https://vk.com/tornado_energy'
                        className='header__right_logo'
                    />
                </header>
                <div className='score' ref={this.scoreRef}>
                    <div className='score__bg' />
                    <div className='score__icon' />
                    <div className='score__value'>
                        <AnimatedNumber
                            value={game.score}
                            duration={400}
                            stepPrecision={0}
                            formatValue={(score) => prettyNumber(score)}
                        />
                    </div>
                </div>
                <div className='frying-screen__round round'>Раунд {game.level}</div>
                <Grill game={game} />
                <div className='boosters'>
                    <button
                        ref={this.beerRef}
                        onClick={this.onClickBeer}
                        onMouseEnter={this.timeToDrinkBeer}
                        className={cn('booster booster_beer', `booster_${game.beer.length ? 'active' : 'idle'}`)}
                    >
                        <span className='booster__icon' />
                        <span className='booster__progress'>
                            <span className='booster__progress-bar' style={{ transform: beerTransform }} />
                        </span>
                        <span className='booster__btn'>Выпить</span>
                    </button>
                    <button
                        ref={this.tornadoRef}
                        onClick={this.onClickTornado}
                        onMouseEnter={this.timeToDrinkTornado}
                        className={cn('booster booster_energy', `booster_${game.booster >= 1 ? 'active' : 'idle'}`)}
                    >
                        <span className='booster__icon' />
                        <span className='booster__progress'>
                            <span className='booster__progress-bar' style={{ transform: tornadoTransform }} />
                        </span>
                        <span className='booster__btn'>Выпить</span>
                    </button>
                </div>
                <RulesLink />
            </div>
        )
    }

    private getUserAction(): string {
        const { game } = this.props
        const isFrying = game.kebabs.some(({ isFrying }) => isFrying)

        return isFrying
            ? 'switch'
            : 'lvlUp'
    }

    private onClickBeer = () => {
        const { game } = this.props
        if (game.beer.length === 0 || !this.beerRef.current) return
        const fromBounds = this.beerRef.current.getBoundingClientRect()
        const toBounds = this.scoreRef.current.getBoundingClientRect()
        const from = { x: fromBounds.x + fromBounds.width / 2, y: fromBounds.y + fromBounds.height / 2 }
        const to = { x: toBounds.x + toBounds.width / 2, y: toBounds.y + toBounds.height / 2 }
        CoinsAnimation.start(from, to)
        soundManager.play(Sounds.coins)

        if (game.beer.length > 0) setTimeout(() => setActiveKebab(0), 500)
        lvlUp(game)
    }

    private onClickTornado = () => {
        const { game } = this.props

        reachGoal('drink')
        if (game.booster < 1 || !this.tornadoRef.current) return
        const fromBounds = this.tornadoRef.current.getBoundingClientRect()
        const toBounds = this.scoreRef.current.getBoundingClientRect()
        const from = { x: fromBounds.x + fromBounds.width / 2, y: fromBounds.y + fromBounds.height / 2 }
        const to = { x: toBounds.x + toBounds.width / 2, y: toBounds.y + toBounds.height / 2 }
        CoinsAnimation.start(from, to)
        soundManager.play(Sounds.energyPicker)
        soundManager.play(Sounds.coins)

        activateBooster(game)
        if (!game.activeKebab) setTimeout(() => setActiveKebab(0), 500)
    }

    private timeToDrinkBeer = () => {
        soundManager.play(Sounds.drinkBeer)
    }

    private timeToDrinkTornado = () => {
        soundManager.play(Sounds.energySelector)
    }
}

export default FryingScreen
