export const COMPONENT_WEIGHTS = {
    bat: 0,
    rat: 0,
    meat: 10,
    onion: 2,
    tomato: 3,
    batCatcher: 0,
    marshmallow: 1,
}

export const COMPONENT_BIN = {
    bat: 1,
    rat: 2,
    meat: 4,
    onion: 8,
    tomato: 16,
    batCatcher: 32,
    marshmallow: 64,
}

export const COMBOS: Array<Combo> = [
    { rule: { marshmallow: 7 }, factor: 3 },
    { rule: { meat: 4, onion: 3 }, factor: 3 },
]

export function calculateScore(components: Array<string>): number {
    const countsMap = components.reduce((acc, component) => {
        if (!acc[component]) acc[component] = 0
        acc[component]++

        return acc
    }, {})

    const combo = COMBOS.find(({ rule }) => {
        return  Object.keys(rule).every((component) => rule[component] === countsMap[component])
    })

    const factor = combo ? combo.factor : 1
    const score = components.reduce((acc, component) => acc + COMPONENT_WEIGHTS[component], 0)

    return score * factor
}

export function isValidComponents(components: Array<string>): boolean {
    let invalidComponents = 0
    for (const component of components) invalidComponents += Number(COMPONENT_WEIGHTS[component] === 0)

    return invalidComponents < 2
}

interface Combo {
    factor: number
    rule: { [key: string]: number }
}
