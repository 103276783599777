import wrapDispatch from '../wrapDispatch'
import GameScreen from './gameScreen'
import { GameKebab, GameState, GameWinnersDay, UPDATE_ACTIVE_KEBAB, UPDATE_GAME_STATE, UPDATE_KEBAB } from './index'
import kebabFactory from './kebabFactory'
import Axios from 'axios'
import store from '../index'
import GameOverReason from './gameOverReason'
import GameOverState from './gameOverState'
import { hasShared, isAuthenticated } from '../user/selectors'
import soundManager from '../../misc/soundManager'

export const initWinners = wrapDispatch(() => {
    return (dispatch) => {
        Axios.get<Array<GameWinnersDay>>('/api/v2/winners')
            .then(({ data }) => {
                dispatch({
                    type: UPDATE_GAME_STATE,
                    payload: { winners: data }
                })
            })
    }
})

export const kebabReadyForFrying = wrapDispatch((gameState: GameState) => {
    const [ kebab ] = gameState.kebabs
    const newScore = gameState.score + kebab.score
    const requestBody: any = { components: kebab.items }
    if (newScore >= 1e6) requestBody.sign = newScore.toString(16)
    Axios.patch(`/api/v2/score/${gameState.scoreId}`, requestBody).then(null, console.error)
    return {
        type: UPDATE_GAME_STATE,
        payload: { score: newScore }
    }
})

export const endGame = wrapDispatch((gameState: GameState, gameOverReason: GameOverReason) => {
    const scoreId = gameState.scoreId
    Axios.patch(`/api/v2/score/${scoreId}/finalize`).then(() => {
        // Update top
        Axios.get(`/api/v2/top/${scoreId}`).then(({ data }) => store.dispatch({
            type: UPDATE_GAME_STATE,
            payload: { top: data }
        }))
    })
    soundManager.pauseAll()

    return {
        type: UPDATE_GAME_STATE,
        payload: {
            top: [],
            gameOverReason,
            activeKebab: null,
            gameOverState: getGameOverState(),
            screen: GameScreen.RESULT,
        }
    }
})

export const activateBooster = wrapDispatch((gameState: GameState) => {
    const payload: GameState = {
        beer: [],
        level: gameState.level,
        score: gameState.score
    }
    for (const beerScore of gameState.beer) {
        payload.level += 1
        payload.score += beerScore
    }
    if (gameState.booster >= 1) {
        payload.booster = gameState.booster - 1
        payload.score *= 2
    }

    Axios.patch(`/api/v2/score/${gameState.scoreId}/tornado`).then()

    return {
        type: UPDATE_GAME_STATE,
        payload
    }
})

export const updateGameState = wrapDispatch((payload: GameState) => ({
    type: UPDATE_GAME_STATE,
    payload
}))

export const finishKebab = wrapDispatch((kebabIdx: number, gameState: GameState) => {
    const payload: GameState = {}
    const kebab = gameState.kebabs[kebabIdx]
    const requestBody: any = { components: kebab.items }

    const newScore = gameState.score + kebab.score
    if (newScore > 1e6) requestBody.sign = newScore.toString(16)
    Axios.patch(`/api/v2/score/${gameState.scoreId}`, requestBody).then(null, console.error)

    payload.beer = [ ...gameState.beer, kebab.score * (kebab.fryingFactor || 1) ]

    payload.kebabs = [ ...gameState.kebabs ]
    payload.kebabs[kebabIdx] = kebabFactory()

    return {
        type: UPDATE_GAME_STATE,
        payload
    }
})

export const lvlUp = wrapDispatch((gameState: GameState) => {
    const payload: GameState = {
        level: gameState.level,
        score: gameState.score,
        beer: []
    }

    for (const beerScore of gameState.beer) {
        payload.level += 1
        payload.score += beerScore
    }

    return {
        type: UPDATE_GAME_STATE,
        payload
    }
})

export const setActiveKebab = wrapDispatch((activeKebab: number | null) => ({
    type: UPDATE_GAME_STATE,
    payload: { activeKebab, screen: GameScreen.CATCHER }
}))

export const updateKebab = wrapDispatch((kebabIdx: number, kebab: GameKebab) => ({
    type: UPDATE_KEBAB,
    payload: { kebabIdx, kebab }
}))

export const updateActiveKebab = wrapDispatch((kebab: GameKebab, changeScreen = false) => {
    return {
        type: UPDATE_ACTIVE_KEBAB,
        payload: { changeScreen, kebab }
    }
})

export const startGame = wrapDispatch((activeKebab = 0) => {
    Axios.put('/api/v2/score').then(({ data: { id: scoreId } }) => {
        store.dispatch({
            type: UPDATE_GAME_STATE,
            payload: { scoreId }
        })
    })

    soundManager.resumeAll()

    return {
        type: UPDATE_GAME_STATE,
        payload: {
            activeKebab,
            level: 1,
            score: 0,
            booster: 0,
            winners: null,
            gameOverReason: null,
            screen: GameScreen.CATCHER,
            kebabs: [
                kebabFactory(),
                kebabFactory(),
            ]
        }
    }
})

function getGameOverState(): GameOverState {
    const { user } = store.getState()

    return !isAuthenticated(user)
        ? GameOverState.unauthorized
        : hasShared(user)
            ? GameOverState.shared
            : GameOverState.authorized
}
