import animate from '../../misc/animate'

const CoinsAnimation = new class {
    private readonly coins: Array<HTMLDivElement>

    constructor() {
        this.coins = Array.prototype.slice.call(document.querySelectorAll('.coin-prefab'))
    }

    start(from: { x: number, y: number }, to: { x: number, y: number }): void {
        this.coins.forEach((coin, idx) => {
            coin.style.transform = `translate(${from.x}px, ${from.y}px)`
            setTimeout(() => {
                coin.classList.add('coin-prefab_animate')
                animate((timeFraction) => {
                    coin.style.transform = `translate(${from.x + (to.x - from.x) * timeFraction}px, ${from.y + (to.y - from.y) * timeFraction}px) scale(${1 + timeFraction})`
                }, 500, () => coin.classList.remove('coin-prefab_animate'))
            }, 100 * idx)
        })

    }
}()

export default CoinsAnimation
