import './Title.sass'
import React, { Component } from 'react'
import { startGame } from '../../store/game/actions'
import { GameState } from '../../store/game'
import GameScreen from '../../store/game/gameScreen'
import RulesLink from '../Rules'
import { reachGoal } from '../../misc/reachGoal'
import soundManager, { Sounds } from '../../misc/soundManager'

interface TitleScreenProps {
    state: string
    game: GameState
}
class TitleScreen extends Component<TitleScreenProps> {

    shouldComponentUpdate(nextProps: Readonly<TitleScreenProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        return this.props.state !== nextProps.state || this.props.game.screen !== GameScreen.CATCHER
    }

    render() {
        const { state } = this.props

        return (
            <div className={`screen title-screen screen-${state}`}>
                <header className='header'>
                    <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href='https://aviasales.ru'
                        className='header__left_logo'
                    />
                    <a
                        target='_blank'
                        rel='noopener noreferrer'
                        onClick={() => reachGoal('tornado')}
                        href='https://vk.com/tornado_energy'
                        className='header__right_logo'
                    />
                </header>
                <div className='title-screen__content'>
                    <div className='title-screen__title'>Жарим дома</div>
                    <div className='title-screen__text'>Жарь шашлык и пей Торнадо на карантине, не выходя из дома</div>
                </div>
                <button
                    onClick={this.onPlay}
                    className='title-screen__btn'
                >
                    <span className='title-screen__btn-text'>Играть</span>
                </button>
                <RulesLink />
            </div>
        )
    }

    private onPlay = () => {
        startGame()
        soundManager.setEnabled(true)
        soundManager.play(Sounds.btnPress)
        reachGoal('startgame')
    }
}

export default TitleScreen
