import { BodyBounds } from './isIntersects'

const Viewport = new class Viewport {
    public readonly bounds: BodyBounds = {
        x: 0,
        y: 0,
        width: window.innerWidth,
        height: window.innerHeight,
    }

    get width(): number {
        return this.bounds.width
    }

    get height(): number {
        return this.bounds.height
    }

    private readonly handlers: Array<() => void> = []

    constructor() {
        this.updateBounds()
        window.addEventListener('resize', () => {
            this.updateBounds()
            this.handlers.forEach((handler) => handler())
        })
    }

    public getScale(is2x = false): number {
        const { width, height } = this.bounds
        const factor = is2x ? 0.5 : 1

        if (width < 764) {
            // Webview
            if ((width / height) >= (375 / 562)) return height / 568 * factor
            // Mobile
            return width / 320 * factor
        } else {
            // Desktop
            return height / 768 * factor
        }
    }

    public addEventListener(handler: () => void): void {
        this.handlers.push(handler)
    }

    public removeEventListener(handler: () => void): void {
        const handlerIdx = this.handlers.indexOf(handler)
        if (handlerIdx === -1) return
        this.handlers.splice(handlerIdx, 1)
    }

    private updateBounds = () => {
        this.bounds.width = window.innerWidth
        this.bounds.height = window.innerHeight
    }
}()

export default Viewport
