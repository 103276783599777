import bg from './assets/img/bg.jpg'
import leftLogo from './assets/img/leftLogo.svg'
import leftLogoBg from './assets/img/leftLogoBg.svg'
import playBtn from './assets/img/playBtn.svg'
import playBtnParticles from './assets/img/playBtnParticles.svg'
import projectLogo from './assets/img/projectLogo.svg'
import rightLogo from './assets/img/rightLogo.svg'
import rightLogoBg from './assets/img/rightLogoBg.svg'
import titleContentBg from './assets/img/titleContentBg.svg'
import grillBg from './assets/img/grill.png'
import fryingBg from './assets/img/fryingBg.jpg'

import xlBg from './assets/img/xlBg.jpg'
import xlFryingBg from './assets/img/xlFryingBg.jpg'

const mobileImageUrls: Array<string> = [
    bg,
    leftLogo,
    leftLogoBg,
    playBtn,
    playBtnParticles,
    projectLogo,
    rightLogo,
    rightLogoBg,
    titleContentBg,
    grillBg,
    fryingBg
]

const desktopImageUrls: Array<string> = [
    xlBg,
    leftLogo,
    leftLogoBg,
    playBtn,
    playBtnParticles,
    projectLogo,
    rightLogo,
    rightLogoBg,
    titleContentBg,
    xlFryingBg
]

export function preload(): Promise<void> {
    const imageUrls = window.innerWidth <= 764 ? mobileImageUrls : desktopImageUrls
    const promises = imageUrls.map((src) => new Promise<void>((resolve) => {
        const img = new Image()
        const onLoad = () => {
            resolve()
            img.removeEventListener('load', onLoad)
        }
        img.addEventListener('load', onLoad)
        img.src = src
    }))

    return Promise.all(promises).then(() => undefined)
}
