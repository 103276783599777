import { GameState } from '../../store/game'

const PRESET: FryingTimeline = Object.freeze({
    duration: 13,
    success: 0.4
})

export class FryingHelper {
    private static timelineCache: { [key: string]: FryingTimeline } = {}

    public static getFryingTimeline(level: number): FryingTimeline {
        if (FryingHelper.timelineCache[level]) return FryingHelper.timelineCache[level]
        const duration = Math.max(PRESET.duration - level * 3, 2)
        const success = Math.max(PRESET.success - level / 20, 0.05)

        return FryingHelper.timelineCache[level] = { duration, success }
    }

    public static getFryingGradient(level: number, side: number): string {
        const timeline = FryingHelper.getFryingTimeline(level)
        const rest = 1 - timeline.success
        const sideBonus = rest / 4
        const success = (timeline.success + sideBonus * side) * 100

        return `linear-gradient(180deg, #05FF00 0%, #05FF00 ${success}%, #FFFFFF ${success}%, #FFFFFF 100%)`
        // return `linear-gradient(180deg, #FFFFFF 0%, #FFFFFF ${successEnd}%, #05FF00 ${successEnd}%, #05FF00 ${successStart}%, #FFFFFF ${successStart}%, #FFFFFF 100%)`
        // return `linear-gradient(180deg, #FFFFFF 0%, #FFFFFF ${successStart}%, #05FF00 ${successStart}%, #05FF00 ${successEnd}%, #FFFFFF ${successEnd}%, #FFFFFF 100%)`
    }

    public static getFryingFactor(gameState: GameState, turnTime: number, side: number): number {
        const timeline = FryingHelper.getFryingTimeline(gameState.level)
        const rest = 1 - timeline.success
        const sideBonus = rest / 4
        const success = (timeline.success + sideBonus * side)
        const turnFactor = turnTime / (timeline.duration * 1000)
        const isLate = turnFactor > 1

        return isLate ? 1 : turnFactor < 1 - success ? -1 : 0
    }
}

export interface FryingTimeline {
    duration: number
    success: number
}
