import { Application, AnimatedSprite, Container, filters, Sprite, utils } from 'pixi.js'
import Viewport from './misc/Viewport'
import { cacheTextureAssets, LIGHTNING_SHEET_KEY, TEXTURE_ASSETS, TEXTURE_ASSETS_PRESET } from './assets'
import store, { StoreState } from '../store'
import { GameState } from '../store/game'
import KebabComponentsEmitter from './objects/KebabComponentsEmitter'
import Trap from './objects/Trap'

utils.skipHello()

export default class CatcherGameScene {
    private readonly app: Application
    private readonly stage: Container
    private readonly emitter: KebabComponentsEmitter
    private readonly trap: Trap
    private lightningSprite: AnimatedSprite
    private displacementFilter: filters.DisplacementFilter
    private blurFilter: filters.BlurFilter

    private gameState: GameState = store.getState().game

    constructor(private renderCanvas: HTMLCanvasElement) {
        this.app = new Application({
            transparent: true,
            autoDensity: true,
            width: Viewport.width,
            height: Viewport.height,
            view: this.renderCanvas,
            resolution: devicePixelRatio || 1
        })
        this.stage = new Container()
        this.app.stage.addChild(this.stage)
        this.emitter = new KebabComponentsEmitter(this.app, this.stage, this.gameState)
        this.trap = new Trap(this.gameState, this.app, this.emitter)
        this.app.loader
            .add(LIGHTNING_SHEET_KEY)
            .add(TEXTURE_ASSETS_PRESET)
            .load(this.startup)
        this.app.renderer.resize(Viewport.width, Viewport.height)
        this.app.stop()

        Viewport.addEventListener(() => {
            this.app.renderer.resize(Viewport.width, Viewport.height)
            this.trap.resize()
        })

        store.subscribe(this.storeStateUpdateHandler)
    }

    private startup = () => {
        cacheTextureAssets(this.app.loader.resources)
        this.initFilters()
        this.initLightningSprite()
        this.app.start()
        this.emitter.init()
        this.app.ticker.add(this.update)
    }

    private update = () => {
        if (this.gameState.activeKebab === null) return
        const deltaTime = this.app.ticker.elapsedMS
        this.emitter.update(deltaTime)
        this.trap.update(deltaTime)
    }

    private storeStateUpdateHandler = () => {
        const storeState: StoreState = store.getState()
        const newGameState: GameState = storeState.game

        if (this.gameState.activeKebab === null && newGameState.activeKebab !== null) {
            // Start game
            this.app.start()
            this.emitter.start()
            this.trap.start()
        } else if (this.gameState.activeKebab !== null && newGameState.activeKebab === null) {
            // End game
            this.emitter.stop()
            this.app.stop()
            this.trap.stop()
        }
        this.gameState = newGameState
        this.updateFilters()
        this.emitter.updateGameState(this.gameState)
        this.trap.updateGameState(this.gameState)
    }

    private initLightningSprite(): void {
        const lightningSheet = this.app.loader.resources[LIGHTNING_SHEET_KEY]
        this.lightningSprite = new AnimatedSprite(lightningSheet.spritesheet.animations['lightning'])
        this.lightningSprite.anchor.set(0.5)
        this.lightningSprite.animationSpeed = 0.25
        this.lightningSprite.stop()
        this.lightningSprite.alpha = 0
        this.lightningSprite.onComplete = () => this.lightningSprite.alpha = 0
        this.lightningSprite.onLoop = () => {
            this.lightningSprite.stop()
            this.lightningSprite.alpha = 0
        }
        this.app.stage.addChild(this.lightningSprite)
        this.trap.setLightning(this.lightningSprite)
    }

    private initFilters(): void {
        this.displacementFilter = new filters.DisplacementFilter(new Sprite(TEXTURE_ASSETS.displacementMap))
        this.blurFilter = new filters.BlurFilter(0, 4)
        this.stage.filters = [ this.displacementFilter, this.blurFilter ]
        this.updateFilters()
    }

    private updateFilters(): void {
        if (this.displacementFilter) {
            this.displacementFilter.scale.set(this.gameState.level > 1 ? this.gameState.level * 10 : 0)
        }
        if (this.blurFilter) {
            this.blurFilter.blur = this.gameState.level > 1 ? this.gameState.level * 0.3 : 0
        }
    }
}
