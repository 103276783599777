import './Winners.sass'
import React, { FC } from 'react'
import { GameState, GameWinner, GameWinnersDay } from '../../store/game'
import { UserState } from '../../store/user'
import prettyNumber from '../../misc/prettyNumber'
import { startGame } from '../../store/game/actions'
import { reachGoal } from '../../misc/reachGoal'
import RulesLink from '../Rules'

interface WinnerProps {
    game: GameState
    user: UserState
}
const Winners: FC<WinnerProps> = ({ game }) => {
    const gameWinners: Array<GameWinnersDay> = game.winners.filter(({ winners }) => winners.length > 0)

    return (
        <div className='result result_winners'>
            <div className="winners">
                {gameWinners.map(({ date, winners }, idx) => (
                    <div className='winners__block' key={date}>
                        <div className="winners__title">Победители {(new Date(date).getDate())}&nbsp;апреля</div>
                        <div className="winners__list">
                            {winners.map((item) => (
                                <div className="result-item" key={item.position}>
                                    <div className='result-item__place'>{item.position}</div>
                                    <div className='result-item__name'>{item.name}</div>
                                    <div className='result-item__score'>{prettyNumber(item.score)}</div>
                                </div>
                            ))}
                        </div>
                        {idx !== 0 ? null : (
                            <div className='winners__tip'>
                                Поздравляем! Напишите нам на <a href='mailto:marketing@aviasales.ru'>marketing@aviasales.ru</a>, и мы договоримся&nbsp;о&nbsp;доставке
                            </div>
                        )}
                    </div>
                ))}
                <button className='result__play-again' onClick={() => { startGame(); reachGoal('restartgame')}}>Играть ещё</button>
                <RulesLink />
            </div>
        </div>
    )
}

export default Winners
