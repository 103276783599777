const DAMPING = 0.1
const SPRING_TENSION = 0.1

export class Spring {
    private damping: number = DAMPING
    private tension: number = SPRING_TENSION

    constructor(
        public readonly points: Array<SpringPoint>,
        damping: number = DAMPING,
        tension: number = SPRING_TENSION) {
        this.updateDamping(damping)
        this.updateTension(tension)
    }

    update(newPosition: number, deltaTime: number) {
        this.points.forEach((point, idx, points) => {
            if (idx === 0) point.updateRealPosition(newPosition)
            else point.updateRealPosition(points[idx - 1].position)
            point.update(deltaTime)
        });
    }

    updateDamping(damping: number): void {
        this.damping = damping
        this.points.forEach((point) => point.updateDamping(damping))
    }

    updateTension(tension: number): void {
        this.tension = tension
        this.points.forEach((point) => point.updateTension(tension))
    }
}

export class SpringPoint {
    public position: number
    private velocity: number

    constructor(
        private realPosition: number,
        private damping: number = DAMPING,
        private tension: number = SPRING_TENSION) {
        this.position = realPosition
        this.velocity = 0
    }

    updateRealPosition(realPosition: number): void {
        this.realPosition = realPosition
    }

    update(deltaTime: number) {
        this.velocity = this.velocity * (1 - this.damping) + (this.realPosition - this.position) * this.tension
        this.position = this.position + this.velocity
    }

    updateDamping(damping: number): void {
        this.damping = damping
    }

    updateTension(tension: number): void {
        this.tension = tension
    }
}
