import React, { FC } from 'react'
import prettyNumber from '../../misc/prettyNumber'
import { initWinners, startGame } from '../../store/game/actions'
import { GameState } from '../../store/game'
import { UserState } from '../../store/user'
import RulesLink from '../Rules'
import GameOverState from '../../store/game/gameOverState'
import getGameOverReasonText from './getGameOverReasonText'
import { getUserName } from '../../store/user/selectors'
import { reachGoal } from '../../misc/reachGoal'

interface RatingProps {
    game: GameState
    user: UserState
}
const Rating: FC<RatingProps> = ({ game, user }) => {
    const top = game.top.map((topItem) => topItem.name ? topItem : ({
        ...topItem,
        userId: user.id,
        name: getUserName(user)
    }))

    return (
        <div className='result result_rating'>
            {game.gameOverState === GameOverState.shared
                ? (
                    <>
                        <div className='result__reason'>
                            {getGameOverReasonText(game.gameOverReason)}
                        </div>
                        <div className='result__main'>
                            <div className='result__title'><br />Розыгрыш шашлыка завершен.</div>
                            <div className='result__subtitle'>
                                Рейтинг лучших игроков сегодняшнего дня:
                            </div>
                        </div>
                    </>
                ) : (
                    <div className='result__content'>
                        <div className='result__title'><br />Розыгрыш шашлыка завершен.</div>
                        <div className='result__subtitle'>
                            Рейтинг лучших игроков сегодняшнего дня:
                        </div>
                    </div>
                )
            }

            <div className='result__rating'>
                {top.map((item) => (
                    <div className={`result-item ${item.userId === user.id ? 'owned' : ''}`} key={item.position}>
                        <div className='result-item__place'>{item.position}</div>
                        <div className='result-item__name'>{item.name}</div>
                        <div className='result-item__score'>{prettyNumber(item.score)}</div>
                    </div>
                ))}
            </div>

            <button className='result__play-again' onClick={() => { startGame(); reachGoal('restartgame')}}>Играть ещё</button>
            <button className='result__play-again thin' onClick={() => initWinners()}>Победители прошлых дней</button>
            <RulesLink />
        </div>
    )
}

export default Rating
