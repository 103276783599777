import './UI.sass'
import cn from 'classnames'
import React, { Component } from 'react'
import { Transition } from 'react-transition-group'
import { GameState } from '../store/game'
import TitleScreen from './Title'
import FryingScreen from './Frying'
import ResultScreen from './Result'
import CatcherScreen from './Catcher'
import GameScreen from '../store/game/gameScreen'
import { UserState } from '../store/user'

interface UIProps {
    game: GameState
    user: UserState
}
export default class UI extends Component<UIProps> {

    render() {
        const { game, user } = this.props
        const uiViewClass = game.screen.toLowerCase()

        return (
            <div className={cn('ui', uiViewClass)}>
                <Transition in={game.screen === GameScreen.TITLE} timeout={400}>
                    {(state) => (<TitleScreen game={game} state={state} />)}
                </Transition>
                <Transition in={game.screen === GameScreen.CATCHER} timeout={400}>
                    {(state) => (<CatcherScreen game={game} state={state} />)}
                </Transition>
                <Transition in={game.screen === GameScreen.FRYING} timeout={400}>
                    {(state) => (<FryingScreen game={game} state={state} />)}
                </Transition>
                <Transition in={game.screen === GameScreen.RESULT} timeout={400}>
                    {(state) => (<ResultScreen user={user} game={game} state={state} />)}
                </Transition>
            </div>
        )
    }

}
